import bus from "../bus";
import settingsTemplate from "./agent_splits.html";
import Api from "../api";
import formats from "../models/formats";

let AgentSplits = {
  name: "AgentSplits",

  props: ["caseObject", "caseUsers", "users"],
  watch: {
    defaultAgents: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.splitPercentage();
      },
    },
    exceptions: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.splitPercentage();
      },
    },
    "caseObject.agent_splits_enabled"(newVal, oldVal) {
      if (!newVal) {
        bus.$emit("agent-split-toggled");
      } else {
        this.updateAgentSplit();
      }
    },
    caseUsers(newVal, oldVal) {
      const idNotPresent = this.validateAgents();
      bus.$emit("validateAgent", idNotPresent);
      this.handleExceptionOptions(this.exceptions)
      this.handlePrimaryAgentOption()
    },
  },

  template: settingsTemplate,
  data: function () {
    return {
      agent_splits_enabled: this.caseObject.agent_splits_enabled,
      defaultAgents: {
        primary_agent: { id: 0, split_percentage: 100 },
        // Add more default_agents if needed
      },
      exceptions: [
        // Add more exceptions if needed
      ],
      noException: false,
      errors: [],
      add: 1,
      nonApiUsers: null,
      agents: [],
      exceptionOptions: [

      ],
      defaultScenarioOptions: []
    };
  },
  created() {
    this.agents = [...this.caseUsers];
    this.agents.push({
      text: "the-enrolling-agent",
      value: 0,
    });
    this.nonApiUsers = [...this.users].filter((user) => !user.isApiUser);
    if (this.caseObject.agent_split_json) {
      this.defaultAgents = this.caseObject.agent_split_json.default_scenario;
      this.exceptions = this.caseObject.agent_split_json.exceptions;
      if (this.exceptions.length > 0) {
        this.noException = true;
      }
      this.handleExceptionOptions(this.exceptions)
    }
    this.handlePrimaryAgentOption()
  },
  methods: {
    handlePrimaryAgentOption(){
      const clonedAgent = JSON.parse(JSON.stringify(this.agents))
      this.defaultScenarioOptions = clonedAgent
      const idsToRemove = Object.entries(this.defaultAgents)
      .filter(([key, value]) => key !== 'unless_primary_agent') // Ignore the unless_primary_agent key
      .map(([key, value]) => value.id);

      for (const agent of this.defaultScenarioOptions) {
        if(idsToRemove.includes(agent.value)){
          agent.disabled = true;
        }
        else{
          agent.disabled = false;
        }
      }
    },
    handleExceptionOptions(exceptions){
      if(this.agents.length <= 0 ) return;
      exceptions.forEach((obj, index) => {
        const clonedAgent = JSON.parse(JSON.stringify(this.agents))
        const result = this.completeOptions(index, obj, clonedAgent)
        this.exceptionOptions[index] = result;
      })
    },
    isOptionStriked(agentId) {
      for (let user of this.agents) {
        // Check if the object's id matches the given id and 'disabled' is true
        if (user.value == agentId && user.disabled === true) {
          return true; // Return true if matched
        }
      }
      // Return false if no match found
      return false;
    },
    completeOptions(index, exceptionAgents, clonedAgent){
      // Get the IDs of agents to be removed from the exception object
      // const idsToRemove = Object.values(exceptionAgents).map(agent => agent.id);
      const idsToRemove = Object.entries(exceptionAgents)
        .filter(([key, value]) => key !== 'unless_primary_agent') // Ignore the unless_primary_agent key
        .map(([key, value]) => value.id); // Extract the IDs from the filtered entries

      for (const agent of clonedAgent) {
        if(idsToRemove.includes(agent.value)){
          agent.disabled = true;
        }
        else{
          agent.disabled = false;
        }
      }
      return clonedAgent;
    },
    handleOptionsChange(index){
      const exceptionAgents = this.exceptions[index];
      // Get the IDs of agents to be removed from the exception object
      // const idsToRemove = Object.values(exceptionAgents).map(agent => agent.id);
      const idsToRemove = Object.entries(exceptionAgents)
        .filter(([key, value]) => key !== 'unless_primary_agent') // Ignore the unless_primary_agent key
        .map(([key, value]) => value.id); // Extract the IDs from the filtered entries

      let updatedAgents = [...this.exceptionOptions[index]];
      for (const agent of updatedAgents) {
        if(idsToRemove.includes(agent.value)){
          agent.disabled = true;
        }
        else{
          agent.disabled = false;
        }
      }

      this.exceptionOptions[index] = [...updatedAgents]
      const allOption = [...this.exceptionOptions];
      const obj = [ ...allOption[index]] ;
      allOption[index] = obj;
      this.exceptionOptions = [...allOption];

    },
    validateAgents() {
      this.agents = [...this.caseUsers];
      this.agents.push({
        text: "the-enrolling-agent",
        value: 0,
      });
      const firstObjectIds = [];
      for (const key in this.defaultAgents) {
        if (this.defaultAgents[key].id !== 0) {
          firstObjectIds.push(this.defaultAgents[key].id);
        }
      }
      this.exceptions.forEach((exception) => {
        for (const key in exception) {
          if (exception[key].id !== 0) {
            firstObjectIds.push(exception[key].id);
          }
        }
      });
      // Extract all ids from secondObject
      const secondObjectIds = this.caseUsers.map((obj) => obj.value);

      //iteratate in all secondobjectIds and with the matching id disable false
      // Check if any id from firstObject is not present in secondObject
      const idNotPresent = firstObjectIds.filter(
        (id) => !secondObjectIds.includes(id)
      );

      let idNotPresentSet = Array.from(new Set(idNotPresent));
      idNotPresent.forEach((id) => {
        let found = this.nonApiUsers.find((obj) => obj.value === id);
        if (
          found &&
          !this.caseUsers.some((userObj) => userObj.value === found.value)
        ) {
          this.agents.push({ ...found, disabled: true });
        }
      });
      if (idNotPresentSet.length > 0) {
        return true;
      }
      return false;
    },
    addException() {
      this.noException = true;
      this.exceptions.push({
        unless_primary_agent: {id: 0},
        primary_agent: { id: 0, split_percentage: 100 },
      });
      // todo add this in a try n catch
      const clonedAgent = JSON.parse(JSON.stringify(this.agents))
      this.exceptionOptions.push(clonedAgent)
    },
    addExceptionAgent(index) {
      const allExps = [...this.exceptions];
      for (let i = 1; i < 9; i++) {
        const key = `split_${i}_agent`;
        if (!(key in this.exceptions[index])) {
          allExps[index][key] = { id: 0, split_percentage: 0 };
          break;
          // Exit the loop once a key is added
        }
      }
      this.exceptions = [...allExps];
    },

    addDefaultAgent() {
      for (let i = 1; i < 9; i++) {
        const key = `split_${i}_agent`;
        if (!(key in this.defaultAgents)) {
          this.$set(this.defaultAgents, key, { id: 0, split_percentage: 0 });
          return;
          // Exit the loop once a key is added
        }
      }
    },
    deleteEntry(agentKey) {
      delete this.defaultAgents[agentKey];

      // Reassign keys to maintain order
      let newAgents = {};
      let index = 1;
      for (let key in this.defaultAgents) {
        if (key !== "primary_agent") {
          newAgents["split_" + index + "_agent"] = this.defaultAgents[key];
          index++;
        } else {
          newAgents["primary_agent"] = this.defaultAgents[key];
        }
      }
      this.defaultAgents = newAgents;
      this.handlePrimaryAgentOption()
    },
    deleteExceptionAgent(indexed, agentKey, event) {
      const allExps = [...this.exceptions];
      const obj = { ...allExps[indexed] };
      delete obj[agentKey];
      allExps[indexed] = obj;
      this.exceptions = [...allExps];

      // Reassign keys to maintain order
      let newAgents = {};
      let index = 1;
      for (let key in this.exceptions[indexed]) {
        if (key.startsWith('split')) {
          newAgents["split_" + index + "_agent"] =
            this.exceptions[indexed][key];
          index++;
        } else if(key == 'primary_agent') {
          newAgents["primary_agent"] = this.exceptions[indexed][key];
        }
        else{
          newAgents['unless_primary_agent'] = this.exceptions[indexed][key]
        }
      }
      this.exceptions[indexed] = newAgents;
      // event.srcElement.closest('.row').remove()
      this.splitPercentageException(indexed);
      this.handleOptionsChange(indexed)
    },
    deleteEntireException(index) {
      this.exceptions.splice(index, 1);
    },
    splitPercentageException(key) {
      let splitSum = 0;
      for (const agentKey in this.exceptions[key]) {
        if (agentKey !== "primary_agent") {
          if(this.exceptions[key][agentKey].split_percentage){
            splitSum += parseInt(this.exceptions[key][agentKey].split_percentage);
          }
        }
      }
      let primaryAgentPercentage;
      if(splitSum> 100){
        primaryAgentPercentage =0
      }
      else{
        if (isNaN(Math.abs(100 - splitSum))) {
          primaryAgentPercentage = 0;
        } else {
          primaryAgentPercentage = Math.abs(100 - splitSum);
        }
      }

      this.exceptions[key]["primary_agent"].split_percentage = primaryAgentPercentage;

      this.updateAgentSplit();
      return true;
    },
    splitPercentage() {
      let splitSum = 0;
      for (const agentKey in this.defaultAgents) {
        if (agentKey !== "primary_agent") {
          splitSum += parseInt(this.defaultAgents[agentKey].split_percentage);
        }
      }
      let primaryAgentPercentage;
      if(splitSum> 100){
        primaryAgentPercentage =0
      }
      else{
        if (isNaN(Math.abs(100 - splitSum))) {
          primaryAgentPercentage = 0;
        } else {
          primaryAgentPercentage = Math.abs(100 - splitSum);
        }
      }
      this.defaultAgents["primary_agent"].split_percentage =
        primaryAgentPercentage;
      this.updateAgentSplit();
      return true;
    },
    validatePercentage(key, event) {
      const keyCode = event.keyCode;
      const inputValue = event.target.value;
      if (keyCode < 48 || keyCode > 57 || inputValue.length >= 3) {
        event.preventDefault();
      }
    },
    handleAgentSplit() {
      bus.$emit("agent-split-toggled", false);
    },
    updateAgentSplit() {

      let agent_split_json = {
        default_scenario: this.defaultAgents,
        exceptions: this.exceptions,
      };
      const idNotPresent = this.validateAgents();
      bus.$emit("agentIdMissing", idNotPresent);
      bus.$emit("case-data-changed", {
        agent_split_json: agent_split_json,
      });
    },
    updateCaseData() {
      bus.$emit("case-data-changed", {
        agent_splits_enabled: this.caseObject.agent_splits_enabled, // === "true",
      });
    },
  },
};

export default AgentSplits;
